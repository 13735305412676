import logo from './images/logo_thumb.png';
import './Wall.css';
import TheCalendar from './TheCalendar';
import React from 'react';
import UserProfile from './UserProfile';
import axios from 'axios';
import t from './translations/translate.ts';
import { TranslationContextProvider } from 'schummar-translate/react';
import ConfirmationModal from './ConfirmationModal';
import CompleteModal from './CompleteModal';
import UnassignModal from './UnassignModal';
import Loader from './Loader';
import TaskDateType from './enums/TaskDateType';
import generateMonthIndication from './Indication.ts';
import ErrorPage from './ErrorPage';
import LOAD_ERROR_TYPES from './enums/LoadErrorTypes';
import TaskCardHome from './TaskCardHome.js';
import TaskDayList from './TaskDayList.js';
import dropdown_icon from './images/dropdown.png';
import LogoutButton from './buttons/LogoutButton.js';
import switch_icon from './images/switch_icon.svg';
import ButtonSecondary from './buttons/ButtonSecondary.js';
import isDemoEnabled from './demo_mode/is_demo_enabled.js';
import DemoAccount from './demo_mode/demo_account.js';
import CONSTS from './Constants.js';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import UserMenuButton from './buttons/UserMenuButton.js';
import ProfileModal from './ProfileModal.js';
import CalendarExtention from './calendarTools/CalendarExtention.js';
import { Walktour } from 'walktour';
import ButtonPrimary from './buttons/ButtonPrimary.js';
import ButtonBluePrimary from './buttons/ButtonBluePrimary.js';
import ButtonNext from './buttons/ButtonNext.js';
import ButtonSkip from './buttons/ButtonSkip.js';
import ButtonPrev from './buttons/ButtonPrev.js';
import Classifier from 'ml-classify-text';
import TextField from './fields/TextField.js';
import TextFieldType from './enums/TextFieldTypes.js';
import { removeStopwords, eng, fra } from 'stopword';
import SuggestInstall, { shouldBeInstallDisplayed } from './SuggestInstall.js';

import './DivScroolableStyled.css';
import FamilySwitch from './FamilySwicth.js';

// TODO: use with all texts from backend!!!
function removeDiacritics(str) {
  return str.normalize("NFD").replace(/[\u0591-\u05BD\u05BF-\u05C2\u05C4-\u05C7]/g, "");
}

class Wall extends React.Component {
  constructor(props) {
    super(props);
    var loginOk = true;
    if (!localStorage.getItem("login_familyToken")) {
      loginOk = false;
    }
    //
    const classifier = new Classifier();
    const categories = {

      "medical": [
        "Take prescribed medication.",
        "Schedule a doctor's check-up.",
        "Follow post-surgery recovery plan.",
        "Attend physical therapy sessions.",
        "Learn about regular health screenings.",
        "Refill prescription meds.",
        "Book annual physical exam.",
        "Update vaccination records.",
        "Organize medical documents.",
        "Set reminders for medication.",
        "Research health insurance plans.",
        "Prepare questions for doctor's visit.",
        "Check blood pressure regularly.",
        "Schedule a dental cleaning.",
        "Get a flu shot.",
        "Monitor blood sugar levels.",
        "Join a health and fitness group.",
        "Attend a wellness workshop.",
        "Update family medical history.",
        "Prepare an emergency kit.",
        "Learn about mental health resources.",
        "Schedule eye exams.",
        "Schedule a dermatology appointment.",
        "Get a cholesterol check.",
        "Join a local health club.",
        "Learn CPR.",
        "Follow a balanced diet.",
        "Check for medication interactions.",
        "Attend a health seminar.",
        "Donate blood.",
        "Create a personal health journal.",
        "Learn about genetic testing."
      ],
      "home": [
        "cooking",
        "maintenance repairs",
        "cooking Potato Chicken",
        "Mow the lawn.",
        "Do the laundry.",
        "Organize the laundry room.",
        "Cook a new recipe.",
        "Meal prep for the week.",
        "Bake homemade bread.",
        "Make a grocery shopping list.",
        "Host a dinner party.",
        "Prepare a picnic.",
        "Mop the floors.",
        "Decorate with plants.",
        "Plan kitchen renovation.",
        "Create a cozy home space.",
        "Choose a living room color.",
        "Install solar panels.",
        "Design a wildlife-friendly garden.",
        "Declutter living space.",
        "Fix leaky faucets.",
        "Plan a yard sale.",
        "Update home security.",
        "Organize a pantry.",
        "Research eco-friendly appliances.",
        "Plan a family game night.",
        "Install energy-efficient lighting.",
        "Paint a feature wall.",
        "Organize the garage.",
        "Create a reading nook.",
        "Set up a home office.",
        "Install a rainwater collection system.",
        "Buy new bed linens.",
        "Clean the gutters.",
        "Rearrange furniture for better flow.",
        "Install smart home devices.",
        "Install a home theater system.",
        "Create a photo wall.",
        "Set up a compost bin.",
        "Upgrade bathroom fixtures.",
        "Build a treehouse.",
        "Plant a vegetable garden.",
        "Install blackout curtains.",
        "Organize a home library.",
        "Install new flooring.",
        "Create a backyard fire pit."
      ],
      "childcare": [
        "Start a daily reading routine.",
        "Teach kids basic cooking skills.",
        "Set up a nature exploration day.",
        "Organize a scavenger hunt.",
        "Create a family calendar.",
        "Plan educational trips.",
        "Teach financial literacy.",
        "Schedule dental check-ups.",
        "Enroll in swimming lessons.",
        "Plan a science experiment.",
        "Balance work and childcare.",
        "Enroll in school activities.",
        "Establish newborn care routine.",
        "Find a reliable babysitter.",
        "Research educational toys.",
        "Implement a consistent bedtime routine.",
        "Schedule pediatrician visit.",
        "Plan kids' playdates.",
        "Update child safety measures.",
        "Organize school supplies.",
        "Set up a homework station.",
        "Research child nutrition.",
        "Plan a family outing.",
        "Set up a craft station.",
        "Plan a birthday party.",
        "Create a chore chart.",
        "Join a parent support group.",
        "Schedule a vision screening.",
        "Teach basic first aid.",
        "Plan a camping trip.",
        "Organize a toy swap.",
        "Prepare a weekly meal plan.",
        "Arrange for music lessons."
      ],
      "emotional": [
        "Finding an emotional therapist",
        "Understand defamation laws.",
        "Learn about intellectual property.",
        "Research environmental laws.",
        "Attend a legal aid clinic.",
        "Understand immigration rights.",
        "Learn about family leave laws.",
        "Know your rights as a freelancer.",
        "Understand whistleblower protections.",
        "Research public records access.",
        "Learn about elder law.",
        "Take a digital detox.",
        "Create a meditation space.",
        "Join a book club.",
        "Practice guided imagery.",
        "Attend a yoga retreat.",
        "Create a happiness jar.",
        "Write down daily wins.",
        "Learn a new skill.",
        "Take a nature walk.",
        "Start a pet care routine.",
        "Talk to a therapist.",
        "Practice mindfulness.",
        "Join a support group.",
        "Learn stress management techniques.",
        "Express gratitude daily.",
        "Schedule self-care time.",
        "Start a gratitude journal.",
        "Set boundaries for personal time.",
        "Find a new hobby.",
        "Plan time with friends.",
        "Create a relaxation playlist.",
        "Write a letter to future self.",
        "Write in a journal.",
        "Practice deep breathing exercises.",
        "Spend time in nature.",
        "Create a vision board.",
        "Read a self-help book.",
        "Practice yoga.",
        "Limit screen time.",
        "Start a daily affirmation practice.",
        "Declutter your living space.",
        "Plan a solo trip."
      ],
      "legal_rights": [
        "Research legal rights.",
        "Consult a lawyer.",
        "Learn about contract law.",
        "Understand tenant laws.",
        "Protect creative work.",
        "Attend consumer rights workshops.",
        "Review insurance policies.",
        "Update will and testament.",
        "Research employment rights.",
        "Prepare for a legal consultation.",
        "Document all contractual agreements.",
        "Understand consumer protection laws.",
        "Learn about digital privacy rights.",
        "Learn about family law.",
        "Research tenant-landlord disputes.",
        "Understand small claims court.",
        "Review workplace harassment laws.",
        "Learn about copyright infringement.",
        "Know your rights during a police stop.",
        "Understand tax obligations.",
        "Learn about data protection.",
        "Research consumer debt laws.",
        "Understand inheritance laws.",
        "File taxes annually.",
        "Research tax deductions.",
        "Understand penalties for late tax filing.",
        "Know your rights when issued fines.",
        "Research traffic violation fines.",
        "Understand legal recourse for unjust fines."
      ],
      "finance": [
        "Plan a budget.",
        "Create a budget.",
        "Review investment portfolio.",
        "Save for retirement.",
        "Explore insurance options.",
        "Learn about stock market investing.",
        "Build an emergency fund.",
        "Track daily expenses.",
        "Compare utility providers.",
        "Plan a no-spend week.",
        "Review credit reports.",
        "Set financial goals.",
        "Research retirement accounts.",
        "Educate on tax savings.",
        "Track monthly subscriptions.",
        "Automate savings.",
        "Review monthly bank statements.",
        "Plan a debt repayment strategy.",
        "Research freelance income tips.",
        "Cut unnecessary expenses.",
        "Set up an investment account.",
        "Learn about credit score factors.",
        "Explore passive income ideas.",
        "Review financial goals quarterly.",
        "Set up a savings challenge.",
        "Create a monthly savings plan.",
        "Analyze spending habits.",
        "Learn about cryptocurrency.",
        "Research home buying tips.",
        "Start a side hustle.",
        "Create a financial backup plan.",
        "Compare credit cards.",
        "Review mortgage options.",
        "Learn about estate planning."
      ],


    };

    function splitJoinArr(a) {
      for (var i = 0; i < a.length; i++) {
        a[i] = a[i].replaceAll("'s", "");
        a[i] = removeStopwords(classifier.splitWords(a[i])).join(' ');
      }
      return a;
    }

    classifier.train(splitJoinArr(categories.medical), 'Medical');
    classifier.train(splitJoinArr(categories.home), 'Home');
    classifier.train(splitJoinArr(categories.childcare), 'Childcare');
    classifier.train(splitJoinArr(categories.finance), 'Finance');
    classifier.train(splitJoinArr(categories.legal_rights), 'Legal rights');
    classifier.train(splitJoinArr(categories.emotional), 'Emotional');
    console.log(classifier.model);

    //
    this.state = {
      // for switch
      accountSwitchIsOpen: false,
      //
      selectedTaskName: 'Example 1',
      predictText: '',
      showUserMenuModal: false,
      //
      predictCat: (txt_) => {
        var txt = txt_.replaceAll("'s", "");
        var result = classifier.predict(removeStopwords(classifier.splitWords(txt)).join(' '), 3, 0.0005);
        console.log("[[predict]]", { text: txt, result: result });
        return (result.length == 0 ? 'general' : result[0].label);
      },
      isDemoEnabled: isDemoEnabled(),
      calendarDate: new Date(),
      locale: localStorage.getItem("locale") || "en",
      loginSaved: loginOk || isDemoEnabled(),
      family_token: localStorage.getItem("login_familyToken"),
      user_token: localStorage.getItem("login_userToken"),
      //task_date_type: TaskDateType.All,
      updateData: null,
      wrong_network: false,
      jsonData: null,
      dayIndicators: null,

      taskDateType: TaskDateType.WithDate,
      // take task dialog (is open toogle)
      takeIsOpen: false,
      // complete task dialog (is open toogle)
      taskCompleteIsOpen: false,
      // unassign task dialog (is open toogle)
      taskUnassignIsOpen: false,

      taskOnConfirmation: () => { },

      taskCompleteOnConfirmation: () => { },

      taskUnassignOnConfirmation: () => { }
    };
    console.log("open", this.state.takeIsOpen);
  }

  handleDateChange = (date) => {
    this.setState({ calendarDate: date });
  }

  getDaysInMonth(date) {
    return;
  }

  getSelectedCalendar() {
    return;
  }
  componentDidMount() {


    /////////////////////////////////////////////////////////////////////////////////////////////////




    if ((!isDemoEnabled()) &&
      (localStorage.getItem("login_familyToken") == null || localStorage.getItem("login_userToken") == null)) {
      window.location = "/";
    }
    //
    /*
    family_token: localStorage.getItem("login_familyToken"),
      loginSaved: loginOk || isDemoEnabled(),
      user_token: localStorage.getItem("login_userToken"),
    */
    //
    const fetchData = async () => {
      try {
        var userToken = this.state.user_token;
        var familyToken = this.state.family_token;

        if (this.state.isDemoEnabled) {
          const d = new DemoAccount();
          var data = {
            "responseFamily": d.family,
            "responseProfile": d.supporter,
            "responseTasks": {
              "no_time": d.no_time,
              "events": d.events
            }
          };
        } else {
          const responseFamily = await axios.get(`${CONSTS.BASE_API_URL}/supporters/${userToken}/families/${familyToken}`);
          const responseProfile = await axios.get(`${CONSTS.BASE_API_URL}/supporters/${userToken}`);
          const responseTasks = await axios.get(`${CONSTS.BASE_API_URL}/supporters/${userToken}/families/${familyToken}/tasks`);
          var data = {
            "responseFamily": responseFamily.data,
            "responseProfile": responseProfile.data,
            "responseTasks": responseTasks.data
          };
        }
        var ind = generateMonthIndication(this.state.calendarDate.getMonth(), this.state.calendarDate.getFullYear(), data.responseTasks.events);
        this.setState({ jsonData: data, updateData: fetchData, dayIndicators: ind });
        //
        window.show_notification_success = (msg, header) => NotificationManager.success(header, msg);
        //
        console.log("LOOP::1", data.responseTasks);
        var detectionsSaved = Array();
        var translationsSaved = Array();
        //////////////////////////////////////////////////////////////////////////////////
        for (var i = 0; i < data.responseTasks.events.length; i++) {
          this.setState({ jsonData: data, updateData: fetchData, dayIndicators: ind });
          console.log("loop:1", data.responseTasks.events[i]);
          var t0 = data.responseTasks.events[i].title;
          console.log('title_', data.responseTasks.events[i]);
          // TODO now
          // check if selected day!
          // cache responses
          var lng = '';
          if (typeof detectionsSaved[t0] === 'undefined') {
            const res = await fetch("https://translate_api.ludenlabs.com/detect", {
              method: "POST",
              body: JSON.stringify({
                q: t0,
              }),
              headers: { "Content-Type": "application/json" }
            });
            lng = (await res.json())[0].language;
          } else {
            lng = detectionsSaved[t0];
          }
          detectionsSaved[t0] = lng;
          var source_lng = lng;
          if (lng == "he") {
            lng = "iw";
          }
          if (lng != this.state.locale) {

            if (typeof translationsSaved[t0] === 'undefined') {
              const res = await fetch("https://translate_api.ludenlabs.com/translate", {
                method: "POST",
                body: JSON.stringify({
                  q: t0,
                  source: source_lng,
                  target: this.state.locale == "iw" ? "he" : this.state.locale,
                  format: "text",
                  alternatives: 0,
                }),
                headers: { "Content-Type": "application/json" }
              });
              data.responseTasks.events[i].title = (await res.json()).translatedText;
              translationsSaved[t0] = data.responseTasks.events[i].title;
            } else {
              data.responseTasks.events[i].title = translationsSaved[t0];
            }
          }
        }
        //////////////////////////////////////////////////////////////////////////////////
        for (var i = 0; i < data.responseTasks.no_time.length; i++) {
          this.setState({ jsonData: data, updateData: fetchData, dayIndicators: ind });
          console.log("loop:1", data.responseTasks.no_time[i]);
          var t0 = data.responseTasks.no_time[i].title;
          const res = await fetch("https://translate_api.ludenlabs.com/detect", {
            method: "POST",
            body: JSON.stringify({
              q: t0,
            }),
            headers: { "Content-Type": "application/json" }
          });
          var lng = (await res.json())[0].language;
          var source_lng = lng;
          if (lng == "he") {
            lng = "iw";
          }
          if (lng != this.state.locale) {
            const res = await fetch("https://translate_api.ludenlabs.com/translate", {
              method: "POST",
              body: JSON.stringify({
                q: t0,
                source: source_lng,
                target: this.state.locale == "iw" ? "he" : this.state.locale,
                format: "text",
                alternatives: 0,
              }),
              headers: { "Content-Type": "application/json" }
            });
            data.responseTasks.no_time[i].title = (await res.json()).translatedText;
          }
        }
        for (var i = 0; i < data.responseTasks.events.length; i++) {
          console.log("loop:2", data.responseTasks.events[i]);
        }
        //
        this.setState({ jsonData: data, updateData: fetchData, dayIndicators: ind });


      } catch (error) {
        if (error.response && typeof error.response.status == 'number') {
          window.location = "/";
        }
        console.error('Error fetching data:', error);
        this.setState({ wrong_network: true });
      }
    };
    fetchData();
  }
  render() {
    window.showProfileModalDialog = () => { this.setState({ showUserMenuModal: true }) }
    //
    function groupAndCountTasks(tasks) {
      const groupedTasks = tasks.reduce((accumulator, currentValue) => {
        const date = new Date(currentValue.date);
        const month = date.getMonth();
        const day = date.getDate();
        const year = date.getFullYear();
        const key = `${year}-${month}-${day}`;
        if (!accumulator[key]) {
          accumulator[key] = { month, monthDay: day, year, count: 0 };
        }
        accumulator[key].count++;
        return accumulator;
      }, {});
      const result = Object.values(groupedTasks);
      return result;
    }
    //
    if (this.state.wrong_network) {
      return <ErrorPage errType={LOAD_ERROR_TYPES.NETWORK_ERROR} />;
    }
    if (!this.state.loginSaved) {
      return <ErrorPage errType={LOAD_ERROR_TYPES.AUTH_TOKEN_NOT_FOUND} />;
    }
    if (this.state.jsonData == null) return Loader();
    var locale = this.state.locale;
    document.body.style.direction = locale === 'iw' ? 'rtl' : 'ltr';
    console.log("profile", this.state.jsonData);

    function areDatesEqual(date1, date2) {
      return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
      );
    }

    var taskCounter = this.state.jsonData.responseFamily.tasks_counter;
    var taskListWithDate = this.state.jsonData.responseTasks.events;

    for (var i = 0; i < taskListWithDate.length; i++) {
      var dateStart = new Date(taskListWithDate[i].date + "T" + taskListWithDate[i].start_at).toLocaleTimeString("ru");
      dateStart = dateStart.split(":")[0] + ":" + dateStart.split(":")[1];
      var dateEnd = new Date(taskListWithDate[i].date + "T" + taskListWithDate[i].end_at).toLocaleTimeString("ru");
      dateEnd = dateStart.split(":")[0] + ":" + dateStart.split(":")[1];
      var d = new Date(taskListWithDate[i].date + "T" + taskListWithDate[i].start_at);

      var d_with_time = new Date(taskListWithDate[i].date + "T" + taskListWithDate[i].start_at);

      d.setHours(0);
      d.setMinutes(0);
      d.setSeconds(0);
      d.setMilliseconds(0);
      taskListWithDate[i].d_with_time = d_with_time;
      taskListWithDate[i].js_date = d;
      taskListWithDate[i].time_start = dateStart;
      taskListWithDate[i].time_end = dateEnd;
      console.log("a task", taskListWithDate[i]);
    }
    taskListWithDate.sort((a, b) => a.d_with_time.getTime() - b.d_with_time.getTime());

    //
    var taskListDateless = this.state.jsonData.responseTasks.no_time;

    taskListDateless.sort((a, b) => a.id - b.id);
    //.status == "in_progress"
    taskListDateless.sort((a, b) => b.status == "in_progress" - a.status == "in_progress");
    taskListDateless.sort((a, b) => b.assigned_on_me - a.assigned_on_me);


    const monthsArray = [
      t("january"),
      t("february"),
      t("march"),
      t("april"),
      t("may"),
      t("june"),
      t("july"),
      t("august"),
      t("september"),
      t("october"),
      t("november"),
      t("december"),
    ];
    var dayList = [];
    console.log("family___", this.state.jsonData.responseFamily.coordinator.formatted_phone_number);
    for (var i = 0; i < /*this.state.dayIndicators.days_with_tasks.length*/ 1 && this.state.dayIndicators.days_with_tasks.length > 0; i++) {
      var d = this.state.dayIndicators.days_with_tasks[i];
      console.log("day", d);
      var tempDate = new Date(this.state.calendarDate);
      //tempDate.setDate(d);
      //console.log("temp_date", tempDate);
      var dayList_ = taskListWithDate.filter(e => areDatesEqual(e.js_date, this.state.calendarDate) && e.status != "completed");
      console.log("dayList", dayList);



      dayList.push({
        el_id: `tasks_of_day_${d}`,
        currentDay: areDatesEqual(tempDate, new Date()),
        day: d, tasks: dayList_.map((itm, ind) => <TaskCardHome

          taskDate_obj={new Date(itm.date)}

          taskCategory={itm.category || this.state.predictCat(itm.title + ' ' + itm.description) || 'Not detected'}
          myTask={itm.assigned_on_me}
          coordinatorName={this.state.jsonData.responseFamily.coordinator.full_name}
          coordinatorNumber={this.state.jsonData.responseFamily.coordinator.formatted_phone_number}
          isEvent={true}
          isTaken={itm.status == "in_progress"}
          taskId={itm.id}

          key={itm.id}
          onTaken={this.state.updateData}
          taskName={itm.title}
          taskDescription={itm.description}
          taskTime={itm.time_start}
          timeRange={
            itm.time_start +
            "-" + itm.time_end
          }
          taskDate={
            <span> <span>{itm.date.split("-")[2]}</span> <span>{monthsArray[itm.date.split("-")[1] - 1]}</span></span>
          }
        />)
      });
    }

    if (
      this.state.calendarDate.getMonth() == new Date().getMonth() &&
      this.state.calendarDate.getFullYear() == new Date().getFullYear()
    ) {

      console.log("current month is selected");
      if (
        dayList.length == 0 ||
        (taskListWithDate.filter(e => areDatesEqual(new Date(e.date), this.state.calendarDate) && e.status != "completed").length == 0)
      ) {
        var d = new Date().getDate();
        dayList.unshift({
          el_id: `tasks_of_day_${d}`,
          currentDay: true,
          day: d,
          tasks: [
            <div style={{ padding: 15, fontSize: 14 }}>
              {t('onlyDatelessStr')}
            </div>,
            taskListDateless.map((item, index) => (
              <TaskCardHome
                taskCategory={item.category || this.state.predictCat(item.title + ' ' + item.description) || 'Not detected'}
                coordinatorName={this.state.jsonData.responseFamily.coordinator.full_name}
                coordinatorNumber={this.state.jsonData.responseFamily.coordinator.formatted_phone_number}
                isEvent={false}
                myTask={item.assigned_on_me}
                isTaken={item.status == "in_progress"}
                taskId={item.id}
                taskName={item.title}
                taskCards=''
                timeRange=''
                taskDate=''
                onTaken={this.state.updateData}
                taskDescription={item.description}
                taskTime='' />
            ))
          ],

        });
      } else {
        // find today's list and push dateless
        // ....
        var wasFound = false;

        /*for (var i = 0; i < dayList.length && !wasFound; i++) {
          var itm = dayList[i];
          if (itm.currentDay) {
            wasFound = true;
            itm.tasks.push(<div style={{ padding: 15, fontSize: 14 }}>
              {t('datelessStr')}
            </div>);
            itm.tasks = itm.tasks.concat(taskListDateless.map((item, index) => {
              return <TaskCardHome
                taskCategory={this.state.predictCat(item.title + ' ' + item.description) || 'Not detected'}
                myTask={item.assigned_on_me}
                coordinatorName={this.state.jsonData.responseFamily.coordinator.full_name}
                coordinatorNumber={this.state.jsonData.responseFamily.coordinator.formatted_phone_number}
                isEvent={false}
                isTaken={item.status == "in_progress"}
                taskId={item.id}
                taskName={item.title}
                taskCards=''
                timeRange=''
                taskDate=''
                onTaken={this.state.updateData}
                taskDescription={item.description}
                taskTime='' />
            }

            ));
          }
          //
          

          //
        }*/
        //////////////////// END OF LOOP
        dayList[dayList.length - 1].tasks = dayList[dayList.length - 1].tasks.concat(taskListDateless.map((item, index) => {
          return <TaskCardHome

            taskDate_obj={null}
            taskCategory={item.category || this.state.predictCat(item.title + ' ' + item.description) || 'Not detected'}
            myTask={item.assigned_on_me}
            coordinatorName={this.state.jsonData.responseFamily.coordinator.full_name}
            coordinatorNumber={this.state.jsonData.responseFamily.coordinator.formatted_phone_number}
            isEvent={false}
            isTaken={item.status == "in_progress"}
            taskId={item.id}
            taskName={item.title}
            taskCards=''
            timeRange=''
            /*taskDate = ,*/
            onTaken={this.state.updateData}
            taskDescription={item.description}
            taskTime='' />
        }

        ));
        ///////////////////
      }
    }

    const defaultTourButtons = (props) => <div style={{ padding: 1, paddingTop: 15 }}>
      <table style={{ width: '100%' }}><tbody><tr>
        <td style={{ width: 50 }}> <ButtonSkip onClick={() => {
          localStorage.setItem('skipped', 'true');
          props.close();
        }} /> </td>
        <td> &nbsp; </td>
        <td style={{ width: 70 }}><ButtonPrev onClick={() => props.prev()} /></td>
        <td style={{ width: 70 }}><ButtonNext onClick={() => props.next()} /></td>
      </tr></tbody></table>
    </div>


    return (
      <TranslationContextProvider locale={this.state.locale}>


        <ProfileModal
          setLocale={(value) => this.setState({ locale: value })}
          userPhone={this.state.jsonData.responseProfile.formatted_phone_number}
          fullName={this.state.jsonData.responseProfile.full_name}
          isOpen={this.state.showUserMenuModal}
          close={() => this.setState({ showUserMenuModal: false })} />


        <SuggestInstall closeBanner={() => this.setState()} />

        <div id='wall_div_main'
          className={this.scrollTop > 50 ? 'DivScroolableStyled' : 'DivScroolableStyledHidden'}
          onScroll={() => {
            const d = document.querySelector("#wall_div_main");
            document.querySelector('#wall_div_main').className = d.scrollTop > 40 ? 'DivScroolableStyled' : 'DivScroolableStyled DivScroolableStyledHidden';
          }}
          style={{
            overflowY: 'scroll', overflowX: 'hidden', width: '98%', height: window.innerHeight - 65 -

              (parseInt(shouldBeInstallDisplayed() ? ((1 / window.innerWidth) * 54000) : 0))

          }}>



          <div>

            <div style={{ textAlign: this.state.locale == "iw" ? 'right' : 'left', padding: 20 }}>
              <img src={logo} style={{ height: 30 }} alt="logo" />
              <br />
            </div>


            <div id='user_profile_component' style={{ width: '100%', margin: 'auto' }}>

              <table style={{ width: 300, margin: 'auto', display: 'none' }} border={0}><tbody><tr>
                <td>
                  <span style={{
                    color: "#7D888D",
                    fontSize: "15px",
                    fontFamily: "Space Grotesk",
                    fontWeight: 300,
                    wordWrap: "break-word"
                  }}> {t('helpingToFamily', '')}</span>
                </td>
                <td>
                  <div style={{ backgroundColor: '#F5F8FF', margin: 10, paddingLeft: 11, paddingRight: 20, borderRadius: 10, border: 'none', width: '160px' }}>
                    <select style={{
                      backgroundColor: '#F5F8FF',
                      '--select-focus': 'transparent', outline: 'none',
                      padding: 11, paddingRight: 20, borderRadius: 10, border: 'none', width: '170px'
                    }}>
                      <option>{this.state.jsonData.responseFamily.title}</option>
                      <option>(switch is available in "Profile")</option>
                      <option></option>
                    </select>
                  </div>
                </td>
              </tr></tbody></table>

              <div
                onClick={() => {
                  if (!this.state.accountSwitchIsOpen) {
                    this.setState({ accountSwitchIsOpen: true });
                  }
                }}
                style={{
                  cursor: 'pointer',
                  userSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none',
                  paddingBottom: '16px', textAlign: this.state.locale == "iw" ? 'right' : 'left',
                  marginLeft: '16px', marginRight: '16px',
                  fontSize: '24px', color: '#313E44'
                }}>{this.state.jsonData.responseFamily.title}
                <img alt='^'
                  width={15}
                  height={15}
                  marginLeft={10}
                  marginRight={10}
                  src={switch_icon}
                  style={{ display: this.state.accountSwitchIsOpen ? 'none' : '' }}
                />
              </div>

              <UserProfile
                initial={taskCounter.initial}
                in_progress={taskCounter.in_progress} />

            </div>
            <h4 style={{ paddingLeft: 14, paddingRight: 14 }}>{t('menu_all_tasks')}</h4>
            <div id='the_calendar_container'>
              <TheCalendar
                indicators={this.state.dayIndicators}
                locale={this.state.locale}
                date={this.state.calendarDate}
                setDate={(date) => {
                  var ind = generateMonthIndication(date.getMonth(), date.getFullYear(),
                    this.state.jsonData.responseTasks.events);
                  this.setState({ calendarDate: date, dayIndicators: ind });
                }}
              />
            </div>

            <div id='week_calendar_component'>
              <CalendarExtention
                counterResults={groupAndCountTasks(this.state.jsonData.responseTasks.events)}
                chooseDay={(d, m, y) => {
                  const newDate = new Date(y, m, d);
                  var ind = generateMonthIndication(newDate.getMonth(), newDate.getFullYear(),
                    this.state.jsonData.responseTasks.events);
                  console.log("date_choose", newDate);
                  this.setState({ calendarDate: newDate, dayIndicators: ind });
                }}
                monthDay={new Date().getDate()}
                selectedMonthDay={this.state.calendarDate.getDate()}
                year={this.state.calendarDate.getFullYear()}
                month={this.state.calendarDate.getMonth()}
              />
            </div>

            <ConfirmationModal
              coordinatorName=''
              coordinatorNumber=''
              title={this.state.selectedTaskName}
              onCancel={() => {
                this.setState({ takeIsOpen: false })
              }}
              onConfirm={this.state.taskOnConfirmation}
              isOpen={this.state.takeIsOpen == true} />
            <CompleteModal
              onCancel={() => {
                this.setState({ taskCompleteIsOpen: false })
              }}
              onConfirm={this.state.taskCompleteOnConfirmation}
              isOpen={this.state.taskCompleteIsOpen == true}
            />
            <UnassignModal
              onCancel={() => {
                this.setState({ taskUnassignIsOpen: false });
              }}
              onConfirm={this.state.taskUnassignOnConfirmation}
              isOpen={this.state.taskUnassignIsOpen == true}
            />

            <div id='take_tasks_component'>
              {dayList.map((item, index) => (<TaskDayList
                isSelectedDay={this.state.calendarDate.getDate() == item.day}
                id={item.el_id}
                key={index.toString()}
                dayWeekNumber={new Date(new Date(this.state.calendarDate).setDate(item.day)).getDay()}
                isCurrentDay={item.currentDay}
                dayNumber={item.day}
                taskCards={<div>{item.tasks}</div>} />
              ))}
            </div>
          </div>
          <br />

        </div>
        <div style={{ position: 'fixed', bottom: 0 }}>
          <NotificationContainer />
        </div>
        {!localStorage.getItem('skipped') ?
          <Walktour
            steps={[
            ]}

          /> : <span>&nbsp;</span>}

        <div>

          <FamilySwitch isOpen={this.state.accountSwitchIsOpen} close={() => {
            console.log("will be closed");
            this.setState({ accountSwitchIsOpen: false });
          }} />
        </div>
      </TranslationContextProvider>
    );
  }
}
export default Wall;

/*
<LogoutButton onClick={() => {
                      localStorage.removeItem("login_familyToken");
                      localStorage.removeItem("demoAccount");
                      window.location.reload();
                    }} />


<td id='user_menu_component' style={{ textAlign: 'right', width: 40 }}>
                    <UserMenuButton onClick={() => this.setState({ showUserMenuModal: true })} />
                    <ProfileModal
                      setLocale={(value) => this.setState({ locale: value })}
                      userPhone={this.state.jsonData.responseProfile.formatted_phone_number}
                      fullName={this.state.jsonData.responseProfile.full_name}
                      isOpen={this.state.showUserMenuModal}
                      close={() => this.setState({ showUserMenuModal: false })} />
                  </td>                    

{window.__install__pwa__ ? <div style={{ width: 140, margin: 'auto', padding: 10, height: 20 }}>
            <ButtonSecondary onClick={() => window.__install__pwa__()}>Install PWA</ButtonSecondary>
          </div> : <span></span>}




<div style={{ display: 'none' }}>classify test

          <TextField type={TextFieldType.TEXT} value={this.state.predictText} onChange={(v) => { console.log(']]', v.target.value); this.setState({ predictText: v.target.value, resultPredicted: this.state.predictCat(v.target.value || '') }); }} />
          is <b>{this.state.resultPredicted || 'general'}</b>
          <div>
            Todo:

            [wihout date column] card
            <br />with time str
            <br />show only for selected day
            <br /> Update view in my tasks button
            <br /> ......
          </div>
        </div>

*/

