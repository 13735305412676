export default {
    welcomeBack: 'Добро пожаловать', /* любой текст, это комментарий */
    myTasks: 'Мои задания',
    helloName: 'Привет, {name}!',
    thanksForFamily: 'Спасибо, что присоединились к поддержке семьи {family_name}.',
    alwaysReady: 'Всегда готовы помочь!',
    progressBar: '{part} из {total} задач взяты',
    allTasks: 'Все задачи',
    datelessTasks: 'Задачи без даты',
    iWillTakeIt: " Возьму ",
    with_date: 'с датой',
    dateless: 'без даты',
    thanksForCaring: 'Словами не передать нашу благодарность. Ваши дела говорят сами за себя. Спасибо!',
    percent38: ' ',
    setSms: 'Напомнить мне СМС',
    addCalendar: 'Добавить в календарь',
    cancel: 'Отмена',
    submit: 'Подтвердить',
    monday: 'Пн',
    tuesday: 'Вт',
    wednesday: 'Ср',
    thursday: 'Чт',
    friday: 'Пт',
    saturday: 'Сб',
    sunday: 'Вс',
    january: "Январь",
    february: "Февраль",
    march: "Март",
    april: "Апрель",
    may: "Май",
    june: "Июнь",
    july: "Июль",
    august: "Август",
    september: "Сентябрь",
    october: "Октябрь",
    november: "Ноябрь",
    december: "Декабрь",
    translate: "Перевести",
    refuseYesNo: "Вы уверены, что хотите отказаться?",
    refuseIfYes: "Если да, пожалуйста, опишите почему в поле ниже.",
    yourAnswer: "Ваш ответ",
    back: "Назад",
    confirm: "Подтвердить",
    areYouSure: "Вы уверены?",
    didYouComplete: "Вы действительно завершили задачу?",
    yes: "Да",
    no: "Нет",
    noTaskThis: "Нет задач на выбранную дату. Также нет задач без даты",
    noTaskTaken: "Вы не взялись ни за одну задачу. Пожалуйста, перейдите на главную страницу задач и взялитесь за задачи",
    noTaskAvailable: "Нет доступных задач. Пожалуйста, попросите владельца семьи создать задачи для семьи",
    errLoginTitle: "Пожалуйста, войдите",
    errAuthTitle: "Неправильная аутентификация",
    errAuthDescription: "Эта страница требует аутентификации, но сохраненные токены недействительны. Попробуйте войти через СМС снова",
    errLoadTitle: "Ошибка на странице",
    errLoadDescription: "Произошло что-то неожиданное. Пожалуйста, свяжитесь со службой поддержки",
    errNetworkTitle: "Недоступно",
    errNetworkDescription: "Пожалуйста, проверьте ваше интернет-соединение и перезагрузите страницу",
    errServerTitle: "Что-то не так на сервере",
    errServerDescription: "Пожалуйста, попробуйте загрузить позже",
    decline: "Отказаться",
    done: "Выполнено",
    onlyDatelessStr: "Нет задач на текущий день, но вы также можете сделать это сегодня:",
    datelessStr: "Также вы можете сделать это сегодня:",
    viewDetails: "Просмотреть детали",
    taken: "Взято",
    noDescriprion: "Без описания",
    tryAgain: "Попробовать снова",
    happyJourney: "Рады, что вы присоединились к нашему пути",
    notForMe: "Не беру",
    mytasks: "Мои задачи",
    signUp: "Зарегистрироваться",
    wNumber: "Какой у вас номер телефона?",
    wName: "Как вас зовут?",
    greatTo: "Рады вас видеть",
    anyHelp: "Любая ваша помощь ценна",
    scrollToAgree: 'Пролистайте, чтобы согласиться',
    ok_scroll: 'ОК',
    howCanI: 'Как я могу помочь',
    anyTaskTake: 'Любая взятая вами задача поможет',
    noTaskYet: 'Пока задач нет',
    welcomeBackName: 'Привет, {first_name}!',
    helpingToFamily: 'Помощь семье ',

    goBack: 'Назад',

    completed_: "Выполнено",
    unassigned_: "Отменено",
    taken_: "Взято",
    notiTaskCompleted: 'Задание "{taskName}" выполнено',
    notiTaskUnassigned: 'Отказ от "{taskName}" принят',
    notiTaskTaken: 'Задание "{taskName}" взято',

    set_: 'Установить',
    pleasePickTime: 'Выберите время',
    pickDate: 'Укажите дату',
    pickTime: 'Укажите время',
    itsTimeFor: "Это время для Apple/Google календаря для",

    signingIn_1: 'Входя в систему вы принимайте ',
    termsOfUse: 'условаия использования',
    signingIn_2: ' и ',
    privacyPolicy: 'политику конфиденциальности',
    signingIn_3: ", которые сохраняет достоинство семьи и оптимизируют помощь",

    thisIsYourTask: 'Это ваше задание',

    switchAccounts: 'Переключение аккаунтов',
    switch: 'Выбрать',
    logout: 'Выйти',
    current: 'текущий',
    help: 'Помощь',


    monday_: 'Пнд',
    tuesday_: 'Втр',
    wednesday_: 'Срд',
    thursday_: 'Чтв',
    friday_: 'Птн',
    saturday_: 'Сбт',
    sunday_: 'Вск',

    signUpRequestError: 'Ошибка запроса регистрации',
    pleaseEnterValidPhone: 'Несуществующий номер телефона. Пожалуйста укажите реальный номер телефона',
    youAreRegistered: 'Вы уже зарегистрированы в этой семье. Ищите ссылку для входа в СМС сообщениях',
    family: 'Семья',

    showInMyTasks: 'Показать в моих',

    category_home: 'Бытовое',
    category_medical: 'Медецинское',
    category_childcare: 'Уход за детьми',
    category_finance: 'Финансовое',
    category_legal_rights: 'Правовое',
    category_emotional: 'Эмоциональное',

    menu_all_tasks: 'Все задания',
    menu_my_tasks: 'Мои задания',
    menu_profile: 'Профиль',

    widget_tasks_available: 'Заданий доступно',
    widget_tasks_taken: 'Заданий взято',

    neverLoseThisPage: 'Не теряйте эту страницу и возможность оказания помощи.',
    install_iOS: 'Получить приложение',
    install_Android: 'Скачать Apk',
    install_PWA: 'Установить PWA',

    button_MyTask: 'Моё',
    button_Today: 'Сегодня',

    install_App: 'Установите наше приложение.',

    //category_medical: 'Медицина',
    //category_home: 'Дом',
    //category_childcare: 'Дети',
    //category_emotional: 'Эмоции',
    category_legal: 'Закон и право',
    category_general: 'Другое',

    language: 'Язык',

    inspiredBy: 'Вдохновлено',

    thankForChoosing: 'Спасибо, что решили поддержать\n{family_name}',
    theFamilyMappedOutTasks: 'Семья наметила задачи, которые могут ее поддержать. Все, что вам осталось сделать, это войти и выбрать задачи',
    everySmallTask: 'Каждое небольшое дело — большое облегчение.',
    letsStart: 'Давайте начнем',
    weEstablishedEnolaTo: 'Мы создали Enola, чтобы помочь семьям в трудные времена эффективно активировать сеть поддержки вокруг них.',
    thankYouForWantedToSupport: 'Спасибо за желание поддержать семью {family_name}',
    wasSentToFamilly: '{full_name}, ваш запрос на присоединение к кругу поддержки был отправлен семье {family_name}. В связи с чувствительностью этого времени, это может занять некоторое время.',
    //
    continue: 'Продолжить',
    supportForTheFamily: 'Поддержка семьи {family_name}',
    yourRequestSentToTheFamily: 'Ваш запрос на присоединение к кругу поддержки был отправлен семье. Большое спасибо за вашу заботу :)',
    //
    youShouldRegister: 'Чтобы сохранить конфиденциальность семьи, вам следует зарегистрироваться в этой системе.',
    afterRegistration: 'После регистрации вы сможете взять задачу после одобрения семьи.',
    someTasksFrom: 'Некоторые задачи от ',


    regConditions: 'Прежде чем продолжить, пожалуйста, найдите время, чтобы прочитать и подтвердить наши Условия использования и Политику конфиденциальности, которые предназначены для защиты семьи и максимальной поддержки ее. Нажатие кнопки подтверждает, что вы прочитали и согласны.',

    regConditions_0: 'Прежде чем мы начнем, из-за чувствительности нашей деятельности, пожалуйста, выделите минуту, чтобы прочитать и одобрить наши ',
    regConditions_url_0: 'Условия использования',
    regConditions_1: ' и ',
    regConditions_url_1: 'Политику конфиденциальности',
    regConditions_2: ', которые разработаны для защиты семьи и максимальной поддержки для них. Нажатие кнопки подтверждает, что вы прочитали и согласны.',

    thisRequiresAuth: 'Для доступа к этой странице требуется аутентификация.',
    demoAccount: 'Демо-аккаунт',

    iCannotTake: 'Я не могу это взять',
    scheduledFor: 'Запланировано на: ',
    taskToCalendar: 'Добавление задачи в календарь — хороший способ отслеживать ее выполнение.',
    iWillTake: 'Возьму это задание',

    sendMessage: 'Отправить SMS',
    sendWhatsApp: 'Отправить WhatsApp',
    forMoreDetails: 'По вопросам по заданию:',

    tasks: 'задач'

} as const;
