export default {
    welcomeBack: 'Willkommen zurück',
    myTasks: 'Meine Aufgaben',
    helloName: 'Hallo {name}!',
    thanksForFamily: 'Danke, dass Sie der Familie {family_name} helfen.',
    alwaysReady: 'Immer bereit zu helfen!',
    progressBar: '{part} von {total} Aufgaben wurden übernommen',
    allTasks: 'Alle Aufgaben',
    datelessTasks: 'Aufgaben ohne Datum',
    iWillTakeIt: "Nehmen",
    with_date: 'mit Datum',
    dateless: 'ohne Datum',
    thanksForCaring: 'Worte können unsere Wertschätzung nicht ausdrücken. Ihre Taten sprechen für sich. Danke!',
    percent38: ' ',
    setSms: 'Erinnere mich per SMS',
    addCalendar: 'Zum Kalender hinzufügen',
    cancel: 'Abbrechen',
    submit: 'Einreichen',
    monday: 'Mo',
    tuesday: 'Di',
    wednesday: 'Mi',
    thursday: 'Do',
    friday: 'Fr',
    saturday: 'Sa',
    sunday: 'So',
    january: "Januar",
    february: "Februar",
    march: "März",
    april: "April",
    may: "Mai",
    june: "Juni",
    july: "Juli",
    august: "August",
    september: "September",
    october: "Oktober",
    november: "November",
    december: "Dezember",
    translate: "Übersetzen",
    refuseYesNo: "Sind Sie sicher, dass Sie ablehnen möchten?",
    refuseIfYes: "Wenn ja, bitte beschreiben Sie den Grund im untenstehenden Feld.",
    yourAnswer: "Ihre Antwort",
    back: "Zurück",
    confirm: "Bestätigen",
    areYouSure: "Sind Sie sicher?",
    didYouComplete: "Haben Sie die Aufgabe tatsächlich abgeschlossen?",
    yes: "Ja",
    no: "Nein",
    noTaskThis: "Keine Aufgaben mit ausgewähltem Datum verfügbar. Auch keine datumlosen Aufgaben",
    noTaskTaken: "Sie haben keine Aufgaben übernommen. Bitte gehen Sie zur Hauptaufgabenseite und übernehmen Sie einige Aufgaben",
    noTaskAvailable: "Keine Aufgaben verfügbar. Bitte bitten Sie den Familienbesitzer, Aufgaben für die Familie zu erstellen",
    errLoginTitle: "Bitte einloggen",
    errAuthTitle: "Falsche Authentifizierung",
    errAuthDescription: "Diese Seite erfordert eine Authentifizierung, aber die gespeicherten Token sind nicht gültig. Versuchen Sie, sich erneut per SMS anzumelden",
    errLoadTitle: "Fehler auf der Seite",
    errLoadDescription: "Etwas Unerwartetes ist passiert. Bitte kontaktieren Sie den Support",
    errNetworkTitle: "Nicht verfügbar",
    errNetworkDescription: "Bitte überprüfen Sie Ihre Internetverbindung und laden Sie die Seite neu",
    errServerTitle: "Etwas stimmt nicht auf dem Server",
    errServerDescription: "Bitte versuchen Sie es später noch einmal zu laden",
    decline: "Ablehnen",
    done: "Fertig",
    onlyDatelessStr: "Keine Aufgabe für den heutigen Tag, aber Sie können dies auch heute tun:",
    datelessStr: "Sie können dies auch heute tun:",
    viewDetails: "Details anzeigen",
    taken: "Genommen",
    noDescriprion: "Keine Beschreibung",
    tryAgain: "Versuchen Sie es erneut",
    happyJourney: "Schön, dass Sie sich\nunserer Reise angeschlossen haben",
    notForMe: "Nicht für mich",
    signUp: "Anmelden",
    wNumber: "Wie lautet Ihre Telefonnummer?",
    wName: "Wie heißen Sie?",
    greatTo: "Schön, Sie hier zu haben",
    anyHelp: "Jede Hilfe, die Sie leisten,\nmacht einen Unterschied",
    scrollToAgree: 'Zum Zustimmen scrollen',
    ok_scroll: 'OK',
    howCanI: 'Wie kann ich helfen',
    anyTaskTake: 'Jede übernommene Aufgabe hilft',
    noTaskYet: 'Noch keine Aufgaben',
    welcomeBackName: 'Hallo {first_name}!',
    helpingToFamily: 'Hilft der Familie ',

    goBack: 'Zurück',

    completed_: 'Abgeschlossen',
    unassigned_: 'Nicht zugewiesen',
    taken_: 'Genommen',
    notiTaskCompleted: 'Aufgabe „{taskName}“ abgeschlossen',
    notiTaskUnassigned: 'Aufgabe „{taskName}“ nicht zugewiesen',
    notiTaskTaken: 'Aufgabe „{taskName}“ angenommen',

    set_: 'Einstellen',
    pleasePickTime: 'Wählen Sie die Uhrzeit aus',
    pickDate: 'Bitte geben Sie ein Datum ein',
    pickTime: 'Geben Sie die Uhrzeit an',
    itsTimeFor: "Es ist Zeit für den Apple/Google-Kalender für",

    signingIn_1: 'Die Anmeldung stellt eine Bestätigung dar ',
    termsOfUse: 'Nutzungsbedingungen',
    signingIn_2: ' und das ',
    privacyPolicy: 'Datenschutzrichtlinie',
    signingIn_3: ", die die Würde der Familie wahren und die Hilfe optimieren",
    thisIsYourTask: 'Das ist Ihre Aufgabe',

    switchAccounts: 'Konten wechseln',
    switch: 'Schalten',
    logout: 'wechseln',
    current: 'aktuell',
    help: 'Helfen',

    monday_: 'Mon',
    tuesday_: 'Die',
    wednesday_: 'Mit',
    thursday_: 'Don',
    friday_: 'Fre',
    saturday_: 'Sam',
    sunday_: 'Son',

    family: 'Familie',

    signUpRequestError: 'Fehler bei der Registrierungsanfrage',
    pleaseEnterValidPhone: 'Bitte geben Sie eine gültige bestehende Telefonnummer ein',
    youAreRegistered: 'Sie sind bereits in der Familie registriert. Bitte suchen Sie den Anmeldelink in der SMS und klicken Sie darauf',

    showInMyTasks: 'In „Meine Aufgaben“',

    category_home: 'Haushalt',
    category_medical: 'Medizinisch',
    category_childcare: 'Kinderbetreuung',
    category_finance: 'Finanziell',
    category_legal_rights: 'Rechtlich',
    category_emotional: 'Emotional',
    category_general: 'Allgemein',

    menu_all_tasks: 'Alle Aufgaben',
    menu_my_tasks: 'Meine Aufgaben',
    menu_profile: 'Profil',

    widget_tasks_available: 'Aufgaben verfügbar',
    widget_tasks_taken: 'Aufgaben übernommen',

    neverLoseThisPage: 'Verlieren Sie diese Seite nicht und die Möglichkeit zu helfen.',
    install_iOS: 'App herunterladen',
    install_Android: 'APK herunterladen',
    install_PWA: 'PWA installieren',

    button_MyTask: 'Meine',
    button_Today: 'Heute',

    install_App: 'Installieren Sie unsere App.',

    language: 'Sprache',

    inspiredBy: 'Inspiriert von',

    thankForChoosing: 'Danke, dass Sie sich entschieden\nhaben, {family_name}\nzu unterstützen',
    theFamilyMappedOutTasks: 'Die Familie hat Aufgaben festgelegt, die ihnen helfen können; alles, was Sie tun müssen, ist sich anzumelden und Aufgaben auszuwählen.',
    everySmallTask: 'Jede kleine Aufgabe ist eine große Erleichterung.',
    letsStart: 'Lass uns anfangen',
    weEstablishedEnolaTo: 'Wir haben Enola gegründet, um Familien in schwierigen Zeiten zu helfen, das Unterstützungsnetzwerk um sie herum effizient zu aktivieren.',
    thankYouForWantedToSupport: 'Danke, dass Sie die Familie {family_name} unterstützen möchten',
    wasSentToFamilly: '{full_name}, Ihre Anfrage, dem Unterstützungskreis beizutreten, wurde an die Familie {family_name} gesendet. Aufgrund der Sensibilität dieser Zeit kann es einige Zeit dauern.',
    //
    continue: 'Weiter',
    supportForTheFamily: 'Unterstützung für die Familie {family_name}',
    yourRequestSentToTheFamily: 'Ihre Anfrage, dem Unterstützungskreis beizutreten, wurde an die Familie gesendet. Vielen Dank für Ihre Sorge :)',
    //
    youShouldRegister: 'Um die Privatsphäre der Familie zu wahren, sollten Sie sich in diesem System registrieren.',
    afterRegistration: 'Nach der Registrierung können Sie eine Aufgabe übernehmen, nachdem die Familie sie genehmigt hat.',
    someTasksFrom: 'Einige Aufgaben von ',



    regConditions: 'Bevor wir beginnen, nehmen Sie sich bitte einen Moment Zeit, um unsere Nutzungsbedingungen und Datenschutzrichtlinien zu lesen und zu bestätigen, da unsere Aktivitäten sensibel sind und diese zum Schutz der Familie und zur Maximierung der Unterstützung entwickelt wurden. Durch Klicken auf die Schaltfläche bestätigen Sie, dass Sie die Bedingungen gelesen und akzeptiert haben.',

    regConditions_0: 'Bevor wir beginnen, nehmen Sie sich bitte aufgrund der Sensibilität unserer Tätigkeit einen Moment Zeit, um unsere ',
    regConditions_url_0: 'Nutzungsbedingungen',
    regConditions_1: ' und ',
    regConditions_url_1: 'Datenschutzrichtlinie',
    regConditions_2: ' zu lesen und zu bestätigen, die zum Schutz der Familie und zur Maximierung der Unterstützung für sie entwickelt wurden. Durch Klicken auf die Schaltfläche bestätigen Sie, dass Sie gelesen haben und zustimmen.',

    thisRequiresAuth: 'Diese Seite erfordert eine Authentifizierung.',
    demoAccount: 'Demo-Konto',

    iCannotTake: 'Ich kann diese Aufgabe nicht übernehmen',
    scheduledFor: 'Geplant für: ',
    taskToCalendar: 'Um den Überblick zu behalten, können Sie die Aufgabe gut Ihrem Kalender hinzufügen.',
    //iWillTake: "I'll take the task",
    iWillTake: "Ich übernehme die Aufgabe",

    sendMessage: 'Nachricht senden',
    sendWhatsApp: 'WhatsApp senden',
    forMoreDetails: 'Für weitere Details:',
    tasks: 'Aufgaben'
} as const;
