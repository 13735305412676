// TODO: 
// Hebrew locale

import React, { useState } from 'react';
import TaskModal from "./TaskModal";
import TaskState from './enums/TaskState';
import t from './translations/translate.ts';
import show_im_my_tasks_icon from './images/show_in_my_tasks_icon.svg';
import ButtonBlueSecondary from "./buttons/ButtonBlueSecondary";
import CollapseContent from './CollapseContent.js';
import OrangeButton from './buttons/OrangeButton.js';
import CONSTS from "./Constants.js";
import { getColor, getIconSrc, getIconLocaleText } from './CategoryFunctions.js';
import ConfirmationModal from './ConfirmationModal.js';
import axios from 'axios';

function formatDate(date) {
    if (date == null) return "";
    const translation = {
        days: ['Sun', 'monday_', 'tuesday_', 'wednesday_', 'thursday_', 'friday_', 'saturday_'],
        months: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
    };
    const day = date.getDate();
    const monthName = translation.months[date.getMonth()];
    return <span> {day} {t(monthName)}</span>;
}

export default function TaskCardHome({
    //isOpen, onConfirm = () => { }, onCancel = () => { },
    onTaken = () => { },
    taskId = 123,
    taskCategory = "CAT",
    isEvent = false,
    isTaken = false,
    taskTime = "16:00",
    timeRange = "16:00 - 18:00",
    taskDate_obj = null,
    taskDate = null,
    taskName = "Appointment with Dr. Cooper",
    taskDescription = "If there is a short comment, it will be located here",
    coordinatorName = "...",
    myTask = false,
    coordinatorNumber = "..."
}) {
    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
    const [isGoogleCalendarEnabled, setGoogleCalendarEnabled] = useState(false);
    //const [isModalOpen, setModalOpen] = useState(false);
    var locale_current = localStorage.getItem("locale") || "en";
    var taskState = isTaken ? TaskState.TAKEN : TaskState.NOT_TAKEN;
    return (<div>
        <ConfirmationModal

            calendarEnabled={isGoogleCalendarEnabled}
            calendarSetEnabled={setGoogleCalendarEnabled}

            coordinatorName={coordinatorName}
            coordinatorNumber={coordinatorNumber}

            title={taskName}
            scheduledDate={taskDate_obj}
            //isGoogleCalendarEnabled={isGoogleCalendarEnabled}
            //setGoogleCalendarEnabled={setGoogleCalendarEnabled}
            isOpen={isConfirmationOpen}
            timeRange={timeRange}
            onConfirm={() => {

                var fToken = localStorage.getItem("login_familyToken");
                var uToken = localStorage.getItem("login_userToken");
                //
                var urlTasks = `${CONSTS.BASE_API_URL}/supporters/${uToken}/families/${fToken}/tasks/${taskId}/assign_me`;
                if (isEvent) {
                    urlTasks = `${CONSTS.BASE_API_URL}/supporters/${uToken}/families/${fToken}/events/${taskId}/assign_me`;
                }

                if (isEvent || (!isGoogleCalendarEnabled)) {
                    axios.put(urlTasks)
                        .then(response => {
                            console.log('[calendar]assign_me request successful:', response.data);
                            if (isGoogleCalendarEnabled) {

                                var dateStart = new Date(response.data.date + "T" + response.data.start_at);
                                var dateEnd = new Date(response.data.date + "T" + response.data.end_at);
                                if (dateEnd < dateStart) {
                                    dateEnd = new Date(dateStart);
                                }

                                if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
                                    || navigator.userAgent.match(/Macintosh/i)) {


                                    window.location.href = (generateAppleLink(
                                        response.data.title,
                                        isEvent ? dateStart : new Date(),
                                        isEvent ? dateEnd : new Date(),
                                        response.data.description,
                                        "Enola family",
                                        isEvent ? taskId : -(taskId),
                                        localStorage.getItem("login_familyToken")
                                    ));

                                } else {
                                    window.open(generateGoogleCalendarURL(
                                        response.data.title,
                                        dateStart,
                                        dateEnd,
                                        response.data.description,
                                        "Enola family",
                                        isEvent ? taskId : -(taskId),
                                        localStorage.getItem("login_familyToken")
                                    ), '_blank');
                                }
                            }
                            window.show_notification_success(t('taken_'), t('notiTaskTaken', { taskName: taskName }));
                            setConfirmationOpen(false);
                        })
                        .catch(error => {
                            console.error('assign_me request error:', error);
                            setConfirmationOpen(false);
                        });
                    //
                    console.log('assign_me DONE');
                    //
                    onTaken();
                    //

                } else {
                    setConfirmationOpen(false);
                    setTimeDialogOpen(true);
                    console.log("dateless_choose_will_open");
                    //
                }
            }}
            onCancel={() => setConfirmationOpen(false)}
        />
        <div onClick={() => {
            console.log("// on user click task card");
            if (!isTaken) {
                setConfirmationOpen(true);
            } else if (myTask) {
                window.show_notification_success("Your task", "It is your task. You can open \"My tasks\" to comlete or cancel this task");
            } else {
                window.show_notification_success("Your task", "This task was already taken by another family member");
            }
        }}
            style={{ width: '96%', maxWidth: 400, margin: 'auto', cursor: 'pointer' }}>
            <table style={{
                paddingTop: '16px',
                paddingBottom: '16px',
                paddingLeft: '12px',
                paddingRight: '12px',
                width: '96%', margin: 10, marginTop: 20, borderRadius: '14px',
                userSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
                boxShadow: '0px 0px 8px 0px #00000040',
                backgroundColor: 'white'/*taskState == TaskState.TAKEN ? (myTask ? 'rgb(247, 218, 203)' : '#F0F0F0') : '#F5F8FF'*/
            }}>
                <tbody>
                    <tr>
                        <td style={{
                            backgroundColor: getColor(taskCategory).background,
                            borderRadius: 12,
                            width: 12
                        }}>
                        </td>
                        <td style={{ width: '6px' }}></td>
                        <td>
                            <table style={{ paddingTop: 10, paddingBottom: 10, width: '98%' }}><tbody><tr>
                                <td style={{ width: 25, padding: 0 }}>
                                    <img style={{ width: 20, height: 20 }} src={getIconSrc(taskCategory)} alt="category" />
                                </td><td style={{ textAlign: locale_current != "iw" ? 'left' : 'right' }}>
                                    <div style={{ color: getColor(taskCategory).text, marginTop: -4, marginLeft: 3, marginRight: 3 }}>
                                        {getIconLocaleText(taskCategory)}
                                    </div>
                                </td>
                                <td style={{ textAlign: locale_current === "iw" ? 'left' : 'right', fontSize: '13.5px' }}>
                                    {(isTaken && !myTask) ? <span style={{ color: '#B8003A', backgroundColor: '#FFDFE9', padding: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 18 }}>{t('taken')}</span> : ''}
                                    {(isTaken && myTask) ? <span style={{ color: '#00951E', backgroundColor: '#EFFFDB', padding: 5, paddingLeft: 8, paddingRight: 8, borderRadius: 18 }}>{t('button_MyTask')}</span> : ''}
                                    {(!isTaken) ? '' : ''}
                                </td>
                            </tr></tbody></table>
                            <b style={{
                                color: '#313E44',
                                fontFamily: 'Space Grotesk',
                                fontSize: '17px',
                                lineHeight: '22.97px',
                                textAlign: 'left',
                            }}>
                                {taskName}
                            </b><br />
                            <div style={{ height: 5 }} />
                            <span style={{
                                color: '#919191',
                                fontSize: '14px',
                                display: isEvent ? '' : 'none'
                            }}>{formatDate(taskDate_obj)} | {timeRange} <br /></span>
                            <div style={{ height: 7 }} />
                            <span>
                                {taskDescription}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>);
}


/*
<tr>
                        <td colSpan={2}>{taskDate}</td>
                        <td style={{ textAlign: 'right' }}> {taskTime}</td>
                    </tr>

<tr style={{ height: 60 }}>
                        <td>
                            <div style={{
                                display: 'inline-block',
                                textAlign: 'center',
                                verticalAlign: 'center',
                                marginTop: myTask ? -18 : -12.4,
                                marginRight: (locale_current === 'iw' ? -12 : 0),
                                marginLeft: (locale_current === 'iw' ? 0 : -12), // locale_current == ?
                                padding: 10, // locale_current == ?
                                fontSize: '11px',
                                borderTopLeftRadius: (locale_current === 'iw' ? 0 : '10px'), // locale_current == ?
                                borderTopRightRadius: (locale_current === 'iw' ? '10px' : 0),
                                borderBottomRightRadius: (locale_current === 'iw' ? 0 : '10px'), // locale_current == ?
                                borderBottomLeftRadius: (locale_current === 'iw' ? '10px' : 0),
                                color: getColor(taskCategory).text, backgroundColor: getColor(taskCategory).background
                            }}>
                                <table style={{ verticalAlign: 'center' }}><tbody><tr>
                                    <td style={{ width: 26, textAlign: 'center' }}><img style={{ width: 20, height: 20 }} src={getIconSrc(taskCategory)} alt="category" /> </td>
                                    <td style={{ verticalAlign: 'center' }}>{getIconLocaleText(taskCategory)}</td>
                                </tr></tbody></table>
                            </div>
                        </td>

                        <td style={{ width: 'auto', userSelect: 'none' }}>&nbsp; &nbsp; &nbsp;</td>

                        <td style={{ width: isTaken ? '80px' : '137px', padding: 0, margin: 0 }}>
                            {myTask ? <p>

                                <div style={{ height: 36, width: 85, /*marginTop: -27,*/
/*textAlign: 'right' }}>
<OrangeButton onClick={() => window.location.href = "/my_tasks?fromPreview=" + (isEvent ? "" : "-") + taskId}>{t('button_MyTask')}</OrangeButton>
</div>

</p> : isTaken /* true*/
/*?
    <div style={{
        width: 97,
        border: '2px solid', fontSize: 17, textAlign: 'center',
        transform: 'rotate(12deg)', fontFamily: 'Rubik Medium'
        , marginLeft: localStorage.getItem("locale") != "iw" ? 25 : 5,
        marginRight: localStorage.getItem("locale") == "iw" ? 25 : 5
        , fontWeight: 1100
    }}>
        {t('taken')}
    </div>
    : <div>
        <ButtonBlueSecondary onClick={() => {
            setConfirmationOpen(true);
        }}>&#10003; {t('iWillTakeIt')}</ButtonBlueSecondary>
    </div>
}

</td>
</tr>




<tr style={{
height: 52, paddingTop: 10, verticalAlign: 'bottom',
display: ''/*taskState == TaskState.TAKEN || true ? 'none' : ''
}}>


<td colSpan="3">

<CollapseContent
header={<b>{taskName} </b>}
main={
    <TaskModal
        date_obj={taskDate_obj}
        isConfirmationOpen={isConfirmationOpen}
        setConfirmationOpen={setConfirmationOpen}
        onTaken={onTaken}
        isEvent={isEvent}
        taskId={taskId}
        coordinatorName={coordinatorName}
        coordinatorNumber={coordinatorNumber}
        taskTime={taskTime}
        taskName={taskName}
        timeRange={timeRange}
        dateStr={taskDate_obj}
        isTaken={taskState == TaskState.TAKEN}
        taskDescription={taskDescription || <i style={{ fontWeight: 100, opacity: 0.6 }}>
            {t('noDescriprion')}
        </i>}
        onCancel={() => { setModalOpen(false) }}
    />
}
/>
</td>
</tr>

*/

















/*
<b>{t('thisIsYourTask')}</b><br />

<td style={{ display: 'none' /*taskState == TaskState.TAKEN ? '' : 'none', width: 1, paddingTop: 4 }}>
<div style={{
    width: 97,
    border: '2px solid', fontSize: 17, textAlign: 'center',
    transform: 'rotate(12deg)', fontFamily: 'Rubik Medium'
    , marginLeft: localStorage.getItem("locale") != "iw" ? -50 : 5,
    marginRight: localStorage.getItem("locale") == "iw" ? -50 : 5
    , fontWeight: 1100
}}>
    {t('taken')}
</div>
</td>
 <tr style={{ display: 'none' }}>
                        <td colSpan={2}> <b>{taskName} </b></td>
                    </tr>


<td style={{ height: 20, paddingTop: 7 }}>
                                                <img style={{ width: 17, height: 17, padding: 0, margin: 0 }} src={show_im_my_tasks_icon} />
                                            </td>

<ButtonBlueSecondary onClick={() => window.location.href = "/my_tasks?fromPreview=" + (isEvent ? "" : "-") + taskId}>
                                        <table style={{ margin: 0, marginTop: -5, marginBottom: -5, marginLeft: -9 }}><tbody><tr>
                                            <td style={{ width: 130, textAlign: 'right', fontWeight: 'normal', fontSize: '14px' }}>
                                                My task
                                            </td>
                                        </tr></tbody></table>

                                    </ButtonBlueSecondary>


<td style={{ display: 'none' }} colSpan="2">
                            <ButtonBlueSecondary onClick={() => setModalOpen(true)}>
                                {t('viewDetails')}
                            </ButtonBlueSecondary>
                        </td>

*/
