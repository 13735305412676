// using react-modal
// accessible as object
// methods: show, set content, cancel, hide, confirm

import ReactModal from "react-modal";
import ButtonPrimary from "./buttons/ButtonPrimary.js"
import cloud_icon from './images/img_cloud_1.png';
import heart_icon from './images/img_heart_1.png';

import phone_icon from './images/phone.svg';
import sms_icon from './images/sms.svg';
import wApp_icon from './images/wa.svg';

import t from './translations/translate.ts';
import ButtonSecondary from "./buttons/ButtonSecondary.js";

function formatDate(date) {
    if (date == null) return "";
    const translation = {
        days: ['Sun', 'monday_', 'tuesday_', 'wednesday_', 'thursday_', 'friday_', 'saturday_'],
        months: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
    };

    const dayName = translation.days[date.getDay()];
    const day = date.getDate();
    const monthName = translation.months[date.getMonth()];
    const year = date.getFullYear();

    return <span> {t(dayName)}, {day} {t(monthName)} {year} </span>;
}

export default function ConfirmationModal(
    { isOpen, onConfirm, onCancel,
        coordinatorName = "",
        coordinatorNumber = "",
        calendarEnabled,
        calendarSetEnabled,
        title = 'TASK_NAME',
        /*timeBegin = '',
        timeEnd = '',*/
        timeRange = null,
        scheduledDate = null
    }) {
    console.log("ConfirmationModal is open", isOpen);
    return (
        <ReactModal
            style={{
                content: {
                    width: 270,
                    //maxWidth: '70%',
                    height: scheduledDate != null ? '530px' : '455px',
                    margin: 'auto',
                    background: '#F5F8FF',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.16)',
                    borderRadius: '12px',
                    direction: localStorage.getItem("locale") == "iw" ? 'rtl' : 'ltr'
                }
            }}
            isOpen={isOpen}
        >
            <div>
                <div style={{ height: 0 }}></div>
                <table onContextMenu={(e) => e.preventDefault()}
                    style={{ width: '100%', direction: 'ltr', userSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none' }}><tbody>

                        <tr> <td>&nbsp;</td>
                            <td style={{ width: 10 }} rowSpan={3}>&nbsp;</td>
                            <td rowSpan={3}>
                                <img alt="heart" style={{ width: 42 }} src={heart_icon} />
                            </td> <td style={{ width: 30 }} rowSpan={3}>&nbsp;</td>
                            <tr> <img style={{ width: 48 }} alt="cloud" src={cloud_icon} /> </tr>
                        </tr>

                        <tr> <td>&nbsp;</td>  <tr>&nbsp;</tr> </tr>

                        <tr> <td><img style={{ width: 90 }} alt="cloud" src={cloud_icon} /></td>  <tr>&nbsp;</tr> </tr>

                    </tbody></table>

                <div style={{ fontSize: '14px' }}>
                    <span style={{
                        color: '#313E44',
                        fontSize: '18px',
                        fontFamily: 'Space Grotesk',
                        fontWeight: 700,
                        wordWrap: 'break-word'
                    }}>{title}</span>

                    <span style={{
                        paddingBottom: 8,
                        display: scheduledDate != null ? '' : 'none'
                    }}>
                        <hr noshade style={{ color: '#E1E4EB', backgroundColor: '#E1E4EB', border: 'none', height: '1.7px', borderRadius: 6 }} />
                        {t('scheduledFor')} <br /></span>
                    <span style={{
                        direction: 'ltr', display: scheduledDate != null ? '' : 'none'
                    }}>{timeRange}<br /></span>
                    <span style={{ display: scheduledDate != null ? '' : 'none' }}
                    >{formatDate(scheduledDate)}</span>
                    <hr noshade style={{ color: '#E1E4EB', backgroundColor: '#E1E4EB', border: 'none', height: '1.7px', borderRadius: 6 }} />

                    <span style={{ fontSize: '14px' }}>
                        {t('forMoreDetails')} <br />
                        <div style={{ height: '4px' }} />

                        <div style={{ height: '6px' }} />
                        <div >



                            <div style={{ display: 'inline-block' }}>
                                <img height={15} src={phone_icon} />
                            </div>
                            &nbsp;&nbsp;
                            <div style={{ direction: 'ltr', display: 'inline-block' }}>
                                <a style={{ color: '#269ACF', textDecoration: 'none', display: 'inline-block' }}
                                    href={"tel:" + coordinatorNumber.replaceAll(" ", "").replaceAll("-", "")}>
                                    {coordinatorNumber}
                                </a>
                            </div>

                        </div>
                        <div style={{ height: '7px', textDecoration: 'none' }} />
                        <a style={{ color: '#269ACF', textDecoration: 'none', display: 'inline-block' }}
                            href={
                                (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
                                    || navigator.userAgent.match(/Macintosh/i)) ?
                                    "sms:" + coordinatorNumber.replaceAll(" ", "").replaceAll("-", "") :
                                    "sms:" + coordinatorNumber.replaceAll(" ", "").replaceAll("-", "") + "?body=" + encodeURIComponent(
                                        `Hello, ${coordinatorName}! I have some questions about task "${title}"`)
                            }>
                            <img height={15} src={sms_icon} />&nbsp;&nbsp;
                            {t('sendMessage')} </a>
                        <div style={{ height: '7px' }} />

                        <a style={{ color: '#269ACF', textDecoration: 'none', display: 'inline-block' }}
                            href={"https://wa.me/" + coordinatorNumber.replaceAll(" ", "").replaceAll("-", "").replaceAll("+", "")
                                + "/?text=" + encodeURIComponent(
                                    `Hello, ${coordinatorName}! I have some questions about task "${title}"`)}>
                            <img height={15} src={wApp_icon} />&nbsp;&nbsp;
                            {t('sendWhatsApp')} </a>
                        <div style={{ height: '7px' }} />
                    </span>

                    <hr noshade style={{ color: '#E1E4EB', backgroundColor: '#E1E4EB', border: 'none', height: '1.7px', borderRadius: 6 }} />
                    <b>
                        {t('taskToCalendar')}
                    </b>

                    <br />
                    <br />
                    <input style={{ userSelect: 'none', cursor: 'pointer' }}
                        checked={calendarEnabled} onChange={(e) => { calendarSetEnabled(!calendarEnabled); }}
                        id="confirm_dialog_calendar_check" type="checkbox" />

                    <b> <label style={{ userSelect: 'none', cursor: 'pointer' }} for="confirm_dialog_calendar_check">{t('addCalendar')}</label></b>


                </div>

                <div style={{ display: 'inline' }}>
                    <div style={{ width: 260, margin: 'auto', paddingTop: 15, height: 35 }}>
                        <ButtonPrimary onClick={onConfirm}>
                            {t('iWillTake')}
                        </ButtonPrimary>
                    </div>

                    <div style={{ width: 260, margin: 'auto', paddingTop: 12, height: 35 }}>
                        <ButtonSecondary onClick={onCancel}>
                            {t('iCannotTake')}
                        </ButtonSecondary>
                    </div>
                    <div style={{ height: '20px' }}>&nbsp;</div>
                </div>
            </div>
        </ReactModal>
    )
}


/*

{
                                            (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
                                                || navigator.userAgent.match(/Macintosh/i)) ?

                                                <a href={"sms:" + coordinatorNumber.replaceAll(" ", "").replaceAll("-", "")}>
                                                    <img width="30" height="30" src={sms_icon} alt="sms" />
                                                </a>
                                                : <a href={"sms:" + coordinatorNumber.replaceAll(" ", "").replaceAll("-", "") + "?body=" + encodeURIComponent(
                                                    `Hello, ${coordinatorName}! I have some questions about task "${taskName}"`)}>
                                                    <img width="30" height="30" src={sms_icon} alt="sms" />
                                                </a>
                                        }

*/