import { useEffect, useState } from "react";
import './FamilySwicth.css';
import t from './translations/translate.ts';
import CONSTS from "./Constants";

export default function FamilySwitch({ isOpen = true, close = () => null }) {
    if (!isOpen) {
        return <div style={{ width: 0, height: 0, display: 'none' }} />
    }
    //
    const [isClosing, setIsClosing] = useState(false);
    const [isStarting, setIsStarting] = useState(true);
    const [isPreloading, setIsPreloading] = useState(true);
    //
    const list_1 = JSON.parse(localStorage.loginHistoryStorage).map((v, index) => {
        return {
            name: v.familyName,
            count: 'Loading...',
            login_creds: {
                familyToken: v.login_familyToken,
                user_token: v.login_userToken
            },
            is_selected: localStorage.login_familyToken === v.login_familyToken
                && localStorage.login_userToken === v.login_userToken
        }
    });
    console.log("real data", list_1);
    //
    const [familySwitchData, setFamilySwitchData] = useState({
        state: 'local_data',
        family_list: list_1
    });
    //
    const defaultHeight = Math.min((73 + (familySwitchData.family_list.length * 50)), (document.body.offsetHeight * 0.75));
    const preloadHeight = defaultHeight + 8;
    //
    function test_1() {
        async function loadFamilyCounts(data) {
            var apiRequest = JSON.stringify({
                payload: data.map((value, index) => {
                    return {
                        user_token: value.login_creds.user_token,
                        family_token: value.login_creds.familyToken,
                    }
                })
            });
            //
            const count_response = await (await fetch(
                `${CONSTS.BASE_API_URL}/supporters/families/task_count`, {
                body: apiRequest,
                method: 'POST',
                headers: {
                    "Content-type": "application/json"
                }
            })).json();
            //
            for (var i = 0; i < data.length; i++) {
                data[i]['count'] = count_response[i];
            }
            setFamilySwitchData({
                state: 'loaded_data',
                family_list: data
            });
        }
        if (isStarting) {
            setIsStarting(false);
            loadFamilyCounts(familySwitchData.family_list);
            setTimeout(()=>setIsPreloading(false), 550);
        }
    }
    useEffect(() => {
        test_1();
    }, [test_1]);
    //
    return <div
        style={{
            userSelect: 'none',
            msUserSelect: 'none',
            MozUserSelect: 'none',
        }}>

        <div

            className={isClosing || isStarting ? "hideSwicthBackground" : "showSwicthBackground"}
            onClick={() => {
                setIsClosing(true);
                setTimeout(close, 600);
            }} style={{
                width: '100%', height: '100vh', //backgroundColor: '#00000066',
                bottom: 0, left: 0, right: 0, zIndex: 200, position: 'fixed'
            }} />

        <div

            className={ (isClosing || isStarting ? "hideSwitch" : isPreloading? "showSwitchPreload":"showSwitch")}
            style={{
                backgroundColor: 'white', width: '100%',
                height: isPreloading ? preloadHeight: defaultHeight,
                overflowY: 'scroll', overflowX: 'hidden',
                bottom: 0, left: 0, right: 0, zIndex: 500, position: 'fixed',
                borderTopLeftRadius: 24, borderTopRightRadius: 24
            }}>
            <div style={{ paddingTop: 16, paddingBottom: 6 }}>
                <div style={{
                    width: 60, height: 4, margin: 'auto',
                    backgroundColor: '#D9D9D9', borderRadius: 40
                }} />
            </div>


            <table style={{ width: '100%', padding: 10 }}>
                <tbody>
                    {familySwitchData.family_list.map((row, index) => <>

                        <tr>
                            <td>
                                <label htmlFor={"family_radio_input_" + index}>
                                    <div>
                                        <span style={{ color: '#000000', fontSize: '16px' }}><b>{row.name}</b></span><br />
                                        <span style={{ color: '#6B6E70', fontSize: '15px' }}>{row.count} {t('tasks')}</span>
                                    </div>
                                </label>
                            </td>
                            <td style={{ textAlign: 'right', width: 35 }}>
                                <label htmlFor={"family_radio_input_" + index}>
                                    <div onClick={row.is_selected? ()=>{
                                        setIsClosing(true);
                                        setTimeout(close, 500);
                                    }:()=>null}>
                                        <input checked={row.is_selected} id={"family_radio_input_" + index}
                                            onChange={({ target }) => {
                                                setTimeout((f) => {
                                                    const creds = f.login_creds;
                                                    localStorage.setItem("login_familyToken", creds.familyToken);
                                                    localStorage.setItem("login_userToken", creds.user_token);
                                                    window.location.reload();
                                                }, 700, familySwitchData.family_list[index]);
                                                console.log(target);
                                                var d = JSON.parse(JSON.stringify(familySwitchData.family_list));
                                                for (var i = 0; i < d.length; i++) {
                                                    d[i].is_selected = (index == i);
                                                }
                                                console.log(d);
                                                setFamilySwitchData({
                                                    state: 'test_data_edited',
                                                    family_list: d
                                                });
                                            }}
                                            style={{ backgroundColor: 'black', height: 20, width: 20 }} type='radio' />
                                    </div></label>
                            </td>
                        </tr>

                        <tr style={{ display: index < familySwitchData.family_list.length - 1 ? '' : 'none' }}>
                            <td colSpan={2}>
                                <div style={{
                                    width: '100%', height: 1, borderRadius: 2,
                                    backgroundColor: '#F0F0F0', margin: 'auto'
                                }} /></td>
                        </tr>

                    </>)}
                </tbody>
            </table>
        </div>
    </div>
};

/*
                familyToken: v.login_familyToken,
                user_token: v.login_userToken
                */
/*const u = `${CONSTS.BASE_API_URL}/supporters/${data[i].login_creds.user_token}/families/${data[i].login_creds.familyToken}`;
const resp = await (await fetch(u)).json();*/

// ${CONSTS.BASE_API_URL}/supporters/{pZ8ZhyF6}/families/{76139661}
//CONSTS.BASE_API_URL

/*
            /supporters/families/task_count
            {"payload":[
                {
                    "user_token": "Ddlo2guq",
                    "family_token": "58983106"
                }
            ]}
            */